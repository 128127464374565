import React from 'react';
import intl from 'react-intl-universal';

import styles from './BindLoading.less';
import store from '../../assets/bind/store.png';
import wheel from '../../assets/bind/wheel.png';

const BindLoading = () => {
  return (
    <div className={styles.loadingCon}>
      <div className={styles.imgCon}>
        <div className={styles.img}>
          <img src={store} className={styles.store} />
          <img src={wheel} className={styles.wheel} />
        </div>
      </div>
      <div className={styles.text}>
        {intl.getHTML('linkStore.loading_Info')}
      </div>
    </div>
  );
};

export default BindLoading;
